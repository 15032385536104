import { Banner } from './Banner'
import { Carousel } from './Carousel'
import { Page } from './Page'

export const Home = () =>
{
    return <Page>
        <Banner/>
        <Carousel/>
    </Page>
}