import '../styles/notFound.scss'
import { Page } from './Page'

export const NotFound = () =>
{
    return <Page>
        <div className="not-found">
            <h1 className="not-found__header">Where's Tom?</h1>
        </div>
    </Page>
}