import '../styles/contact.scss'
import { useState } from 'react'
import { Page } from "./Page"

import TextareaAutosize from 'react-textarea-autosize';

const encode = (data) =>
{
    return Object.keys(data).map((key) => 
    {
        return encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
    }).join("&");
}


export const Contact = () =>
{

    const [post, setPost] = useState({name: "", email: "", message: ""})
    const [isPosted, setPosted] = useState(false);

    const {name, email, message} = post;

    const handleSubmit = (event) =>
    {
        fetch("/",
        {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "contact", ...post })
        })
        .then(() =>
        {
            setPosted(true);
        })
        .catch((err) => console.log(err))
        event.preventDefault();
    }

    const handleChange = (event) =>
    {
        setPost((state) =>
        {
            return {...state, [event.target.name]: event.target.value}
        })
    }

    return <Page>
        {isPosted
        ? <h1 className="contact__header">Roger that!</h1>
        : <div className="contact">
            <h1 className="contact__header">Hit me up.</h1>
            <form className="contact__form" name="contact" onSubmit={handleSubmit}>
                <input className="contact__form__input" type="text" name="name" placeholder="Name" value={name} onChange={handleChange}/>
                <input className="contact__form__input" type="email" name="email" placeholder="Email" value={email} onChange={handleChange}/>
                <TextareaAutosize className="contact__form__text" type="text" name="message" placeholder="Message" minRows={1} maxRows={10} value={message} onChange={handleChange}/>
                <button className="contact__form__btn" type="submit">Submit</button>
            </form>
        </div>}
    </Page>
}