import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './app.scss'
import { Contact } from './components/Contact';
import { Home } from './components/Home';
import { Navbar } from './components/Navbar';
import { NotFound } from './components/NotFound';

function App()
{
  return <div className="app">
    <BrowserRouter>
      <Navbar/>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/contact' element={<Contact/>}/>
        <Route path="*" element={<NotFound/>}/>
      </Routes>
    </BrowserRouter>
  </div>
}

export default App;
