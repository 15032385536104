import { Link } from 'react-router-dom'
import '../styles/navbar.scss'

export const Navbar = () =>
{
    return <div className="navbar">
        <nav className='navbar__menu'>
            <Link className="navbar__menu__link" to="/">Home</Link>
            <Link className="navbar__menu__link" to="/contact">Contact</Link>
            <a className="navbar__menu__link" href="https://linkedin.com/in/tom-penny">LinkedIn</a>
            <a className="navbar__menu__link" href="https://github.com/tom-penny">GitHub</a>
        </nav>
        <div className="navbar__divider"/>
    </div>
}