import {Swiper, Navigation, Pagination} from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { useEffect } from 'react'

import '../styles/carousel.scss'

import projects from '../data/projects.json'
import { ProjectCard } from './ProjectCard'
import { useMediaQuery } from 'react-responsive'

export const Carousel = () =>
{
    const isMobile = useMediaQuery({maxWidth: 600})

    useEffect(() =>
    {
        var swiper = new Swiper('.swiper', {
            modules: [Navigation],
            loop: true,
            speed: 500,
            slidesPerGroup: 1,
            centeredSlides: true,
            watchSlidesProgress: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 50,
                },
                1000: {
                    slidesPerView: 3,
                    spaceBetween: 150,
                },
                // 1500: {
                //     slidesPerView: 4,
                // }
            }
        })
    }, [])

    return <div className='swiper-container'>
        <div className='swiper-button-prev'/>
        <div className='swiper'>
            <div className='swiper-wrapper'>
                {projects.map((project, index) => {
                    return <ProjectCard project={project} key={index}/>
                })}
            </div>
        </div>
        <div className='swiper-button-next'/>
    </div>
}