import { useMediaQuery } from 'react-responsive'
import '../styles/projectCard.scss'

export const ProjectCard = ({project}) =>
{
    const isHandheld = useMediaQuery({maxWidth:1000})

    return isHandheld
    ? <div className='swiper-slide project-card-mobile'>
        <img className='project-card__img' src={project.img}/>
        <div className='project-card__label'>
            <h3 className='project-card__label__header'>{project.title}</h3>
            <span className='project-card__label__span'>{project.description}</span>
            <a href={project.link} className='project-card__label__btn'>Take a look</a>
        </div>
    </div>
    : <a className='swiper-slide project-card' href={`${project.link}`}>
        <div className='container'>
            <img className='project-card__img' src={project.img}/>
            <div className='project-card__label'>
                <h3 className='project-card__label__header'>{project.title}</h3>
                <span className='project-card__label__span'>{project.description}</span>
                <span className='project-card__label__btn'>Take a look</span>
            </div>
        </div>
    </a>
}